.App {
  text-align: center;
}

.App-header {
  background-color: #0b203a;
  padding: 20px;
  color: #6FC7B6;
}

.header-div { 
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
}

.header-div h1{
  font-size: 1.5em;
  color: #6EC7B6;
}

.chat-container {
  margin: 20px;
  padding: 10px 10px 17px 10px; 
  border: 1px solid #ccc;
  height: calc(100vh - 250px);
  overflow: hidden;
  position: relative;
}

.chat-container:has(~ .message-form) {
  height: calc(100vh - 350px);
}


.chat-message {
  margin: 5px 0 10px 0;
  padding: 5px;
  background-color: #f5f5f5;
  text-align: left;
  font-size: 1em;
}

.message-form {
  margin: 20px;
}

.input-wrapper {
  position: relative;
}

.telegram-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #777777;
  cursor: pointer;
}

.options-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 10px;
}

.option-button {
  background-color: #173258;
  color: #ffffff;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.options-scroll-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 50px 0 50px;
}

.options-scroll-container-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 100%;
  background-color: rgba(25, 118, 210, 0.7);
  color: #ffffff;
  padding: 7px;
  font-size: x-small;
}

.arrow {
  font-weight: bolder;
  font-size: larger;
  margin-right: 7px;
  margin-left: 7px;
}

/* Optional: make the buttons a bit smaller in mobile views */
@media (max-width: 768px) {
  .option-button {
      min-width: 50px;
      max-width: 80px;
      font-size: 0.7rem !important;
  }

  .request-call {
    font-size: 0.6rem !important;
  }
}