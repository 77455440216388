.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #f8d7da;
    color: #721c24;
    border: 2px solid #f5c6cb; 
    z-index: 9999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 300px;
    border-radius: 8px;
  }
  
  .popup h2 {
    margin-top: 0;
    font-size: 18px; 
  }
  
  .popup p {
    margin-bottom: 0;
    font-size: 14px; 
  }